import { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { checkAuth, fetchDecoded, fetchDefault, fetchProfile } from "./actions";
import Loading from "./components/Loading";
import Navbar from "./components/Navbar/Navbar";
import NotFound from "./components/NotFound/NotFound";
import Login from "./pages/Login/Login";
const Notice = lazy(() => import("./pages/Notice/Notice"));
const OrganisingCouncil = lazy(() =>
  import("./pages/Organising Council/OrganisingCouncil")
);
const SubCouncilMember = lazy(() =>
  import("./pages/Sub Council/SubCouncilMember")
);
const TSAYCouncil = lazy(() => import("./pages/TSAY Council/TSAYCouncil"));
const Home = lazy(() => import("./pages/Main/Home"));
const Quiz = lazy(() => import("./pages/Quiz/Quiz"));
const QuizQuestion = lazy(() => import("./pages/Quiz/QuizQuestion"));
const QuizResult = lazy(() => import("./pages/Quiz/QuizResult"));
const Divisions = lazy(() => import("./pages/Divisions/Divisions"));
const Corps = lazy(() => import("./pages/Corps/Corps"));
const DidYouKnow = lazy(() => import("./pages/DidYouKnow/DidYouKnow"));
const SubCouncil = lazy(() => import("./pages/Sub Council/SubCouncil"));
const LostAndFound = lazy(() => import("./pages/Lost And Found/LostAndFound"));
const PicOfDay = lazy(() => import("./pages/Pic of the day/PicOfDay"));
const Helpline = lazy(() => import("./pages/Helpline/Helpline"));
const InkhawmStatistics = lazy(() =>
  import("./pages/Inkhawm Statistics/InkhawmStatistics")
);
const TradeItems = lazy(() => import("./pages/Trade Items/TradeItems"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const App = ({ checkAuth, auth, fetchProfile, fetchDecoded, fetchDefault }) => {
  useEffect(() => {
    checkAuth();
    if (auth === 1) {
      fetchAll();
    }
  }, [auth]);

  const fetchAll = async () => {
    await Promise.all([fetchProfile(), fetchDecoded(), fetchDefault()]);
  };
  if (auth === 1) {
    return (
      <Navbar>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path={"/"} exact element={<Home />} />
            <Route path={"/divisions"} exact element={<Divisions />} />
            <Route path={"/corps"} exact element={<Corps />} />
            <Route path={"/quiz"} exact element={<Quiz />} />
            <Route
              path={"/quiz/question/:name/:id"}
              exact
              element={<QuizQuestion />}
            />
            <Route
              path={"/quiz/result/:name/:id"}
              exact
              element={<QuizResult />}
            />
            <Route path={"/did-you-know"} exact element={<DidYouKnow />} />
            <Route path={"/pic-of-the-day"} exact element={<PicOfDay />} />
            <Route path={"/tsay-council"} exact element={<TSAYCouncil />} />
            <Route
              path={"/organising-council"}
              exact
              element={<OrganisingCouncil />}
            />
            <Route path={"/sub-council"} exact element={<SubCouncil />} />
            <Route
              path={"/sub-council/:name/:id"}
              exact
              element={<SubCouncilMember />}
            />
            <Route
              path={"/inkhawm-statistics"}
              exact
              element={<InkhawmStatistics />}
            />
            <Route path={"/trade-items"} exact element={<TradeItems />} />
            <Route path={"/helpline"} exact element={<Helpline />} />
            <Route path={"/lost-and-found"} exact element={<LostAndFound />} />
            <Route path={"/notice"} exact element={<Notice />} />
            <Route path={"/settings"} exact element={<Settings />} />
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 0) {
    return (
      <main>
        <Login />
      </main>
    );
  } else {
    return <Loading />;
  }
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchProfile,
  fetchDecoded,
  fetchDefault,
})(App);
