import { combineReducers } from "redux";
import authReducer from "./authReducer";
import corpsReducer from "./corpsReducer";
import divisionReducer from "./divisionReducer";
import defaultReducer from "./defaultReducer";
import subCouncilReducer from "./subCouncilReducer";
import profileReducer from "./profileReducer";

export default combineReducers({
  auth: authReducer,
  corps: corpsReducer,
  division: divisionReducer,
  profile: profileReducer,
  defaults: defaultReducer,
  subCouncil: subCouncilReducer,
});
