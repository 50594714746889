import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 600,
        fontWeight: 600,
        fontSize: 22,
      }}
    >
      404 Page Not Found
    </div>
  );
};

export default NotFound;
