import api from "../api/api";
import { decodeToken } from "react-jwt";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("admin-auth")) {
      return dispatch({
        type: "AUTH_ADMIN",
      });
    } else {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  const decoded = await decodeToken(token);
  return dispatch({
    type: "FETCH_DECODED",
    payload: decoded,
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  const decoded = await decodeToken(token);
  try {
    const response = await api.get(`/api/admin/${decoded.id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return dispatch({
      type: "FETCH_PROFILE",
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  }
};

export const fetchDefault = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/default`);
    await Promise.all(
      dispatch({
        type: "FETCH_DEFAULT",
        payload: response.data.defaults,
      }),
      dispatch({
        type: "FETCH_DIVISION",
        payload: response.data.division,
      }),
      dispatch({
        type: "FETCH_CORPS",
        payload: response.data.corps,
      }),
      dispatch({
        type: "FETCH_SUB_COUNCIL",
        payload: response.data.sub_council,
      }),
      dispatch({
        type: "FETCH_DEPARTMENT",
        payload: response.data.department,
      }),
      dispatch({
        type: "FETCH_SEMESTER",
        payload: response.data.semester,
      }),
      dispatch({
        type: "FETCH_CAT",
        payload: response.data.cat,
      }),
      dispatch({
        type: "FETCH_NEWS",
        payload: response.data.news,
      }),
      dispatch({
        type: "FETCH_CALENDAR",
        payload: response.data.calendar,
      })
    );
  } catch (error) {
    return;
  }
};

export const logout = () => async (dispatch) => {
  window.location.href = "/";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};
