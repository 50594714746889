import {
  ChevronRightIcon,
  HamburgerIcon,
  LockIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  Image,
  LinkBox,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../actions";
import Prompt from "../Alert/Prompt";

const Navbar = ({ children, logout }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const LINKS = [
    { id: 1, name: "Home", path: "/" },
    { id: 12, name: "Inkhawm Statistics", path: "/inkhawm-statistics" },
    { id: 2, name: "Pic Of The Day", path: "/pic-of-the-day" },
    { id: 13, name: "Lost & Found", path: "/lost-and-found" },
    { id: 14, name: "Trade Items", path: "/trade-items" },
    { id: 3, name: "Quiz", path: "/quiz" },
    { id: 4, name: "Notice", path: "/notice" },
    { id: 5, name: "Did You Know", path: "/did-you-know" },
    { id: 6, name: "Divisions", path: "/divisions" },
    { id: 7, name: "Corps", path: "/corps" },
    { id: 8, name: "TSAY Council", path: "/tsay-council" },
    { id: 9, name: "Organising Council", path: "/organising-council" },
    { id: 10, name: "Sub Council", path: "/sub-council" },
    { id: 15, name: "Helpline", path: "/helpline" },
    { id: 11, name: "Settings", path: "/settings" },
  ];

  const renderDrawer = () => {
    return (
      <Drawer
        placement={"left"}
        onClose={() => setOpenDrawer(false)}
        isOpen={openDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image alt="SAY" src="/logo512.png" width={50} mr={2} />
              IET SAY Congress
            </div>
          </DrawerHeader>
          <DrawerBody>
            <List spacing={1}>
              {LINKS.map((item) => (
                <ListItem
                  key={item.id}
                  as={LinkBox}
                  width="100%"
                  _hover={{
                    background: "#f5f5f5",
                  }}
                  onClick={() => {
                    navigate(item.path);
                    setOpenDrawer(false);
                  }}
                  style={{
                    cursor: "pointer",
                    borderRadius: 5,
                    fontWeight: 500,
                    padding: 8,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      item.path === "/"
                        ? location === "/" && "#3a61ad"
                        : location.includes(item.path) && "#3a61ad",
                    color:
                      item.path === "/"
                        ? location === "/" && "#fff"
                        : location.includes(item.path) && "#fff",
                  }}
                >
                  <div style={{ display: "flex", flexGrow: 1 }}>
                    {item.name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexShrink: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <ChevronRightIcon />
                  </div>
                </ListItem>
              ))}
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };
  return (
    <div style={{ flexGrow: 1 }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: 10,
          alignItems: "center",
          position: "fixed",
          borderBottom: "1px solid #ddd",
          boxShadow: "1px 2px 5px #eee",
          zIndex: 1,
          background: "#fff",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Stack spacing={3} direction="row" alignItems={"center"}>
            <IconButton
              onClick={() => setOpenDrawer(true)}
              variant="ghost"
              icon={<HamburgerIcon />}
              borderRadius="50%"
            />
            <Image alt="SAY" src="/logo512.png" width={50} />
            <Heading size={"md"} verticalAlign="center">
              IET SAY Congress
            </Heading>
          </Stack>
        </div>
        <div style={{ flexShrink: 1, paddingRight: 10 }}>
          <Menu>
            <MenuButton
              as={IconButton}
              bgColor="#fff"
              borderRadius={"50%"}
              aria-label="Options"
              icon={<Image alt="user" src="/user.png" width={30} height={30} />}
            />
            <MenuList>
              <MenuItem icon={<SettingsIcon />}>My Account</MenuItem>
              <MenuItem icon={<LockIcon />} onClick={() => setIsOpen(true)}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <main style={{ paddingTop: 80, zIndex: 1 }}>{children}</main>
      {renderDrawer()}
      <Prompt
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Logout"
        body="Are you sure you want to logout?"
        confirmFunction={logout}
      />
    </div>
  );
};

export default connect(null, { logout })(Navbar);
