import { Spinner } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 600,
      }}
    >
      <Spinner size={"xl"} colorScheme="facebook" />
    </div>
  );
};

export default Loading;
