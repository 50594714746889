import {
  Box,
  Button,
  Container,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import { checkAuth } from "../../actions";
import api from "../../api/api";
const Login = ({ checkAuth }) => {
  const toast = useToast();
  const [formValues, setFormValues] = useState({ username: "", password: "" });
  const [isWait, setIsWait] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);
      const response = await api.post(`/api/admin/login`, formValues);
      localStorage.setItem("admin-auth", response.data.token);
      setIsWait(false);
      checkAuth();
    } catch (error) {
      setIsWait(false);
      let description;
      if (error.response.data) {
        description = error.response.data.message;
      } else {
        description = "Something went wrong. Check your internet connection.";
      }
      toast({
        title: "Authentication Failed",
        description: description,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  return (
    <Container maxWidth="sm">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          marginTop={10}
          src="/logo512.png"
          alt="IET SAY Congress"
          width={150}
          height={150}
        />
      </div>
      <Text fontWeight={500} fontSize={22} align="center">
        IET SAY Congress, 2022
      </Text>
      <form onSubmit={onSubmit}>
        <Stack spacing={5} marginTop={5}>
          <Input
            autoFocus
            required
            colorScheme={"facebook"}
            variant="outline"
            placeholder="Username"
            value={formValues.username}
            onChange={(e) =>
              setFormValues({ ...formValues, username: e.target.value })
            }
          />
          <Input
            required
            colorScheme={"facebook"}
            variant="outline"
            placeholder="Password"
            type="password"
            value={formValues.password}
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
          />
          <Button colorScheme="facebook" type="submit" disabled={isWait}>
            {isWait ? <Spinner size="sm" /> : "Login"}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default connect(null, { checkAuth })(Login);
