import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

const Prompt = ({ isOpen, setIsOpen, title, body, confirmFunction }) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              size="sm"
              colorScheme={"red"}
              variant="solid"
              onClick={confirmFunction}
            >
              Yes, Confirm
            </Button>
            <Button
              size="sm"
              colorScheme="facebook"
              variant={"outline"}
              onClick={() => setIsOpen(false)}
              ml={3}
            >
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Prompt;
